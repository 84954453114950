import { defineStore } from "pinia";
import { getSystemInfo, getSystemConfig } from "../api/index"

export const configStore = defineStore({
    id: 'myConfigStore',
    state: () => ({
        config: {
            imei: '', // 设备IMei,
            time: '30', // 日期更新时间
            rankingTime: 60, // 排行榜刷新时间
            productCode: '800801', // 项目code
            httpUrl: 'http://39.103.170.133:8888', // 接口请求地址
            weaTherKey: 'c2afeb724a2b4f0c810bb306c2a1c20a', // 和风天气Key
        },
        trailData: {
            bannerList: [], // 步道缩略轮播图
            province: '', // 省
            city: '',  // 市
            area: '', // 区
            companyName: '', // 版权名称
            logo: '', // logo
            qr: '', // 二维码
            title: '可视化数据平台', // 名称
        }
    }),

    actions: {
        // 初始化设备配置
        async initProject() {
            return new Promise((resolve, reject) => {
                getSystemConfig({imei: this.config.imei}).then( async res => {
                    localStorage.locationId = res.locationId;
                    localStorage.projectCode = res.projectCode;
                    let sysInfo = await getSystemInfo();
                    for(const i in sysInfo) {
                        for(const j in this.trailData) {
                            if(i === j) this.trailData[j] = sysInfo[i]
                        }
                    }
                   resolve()
                })
            })
        }, 

        async initConfig() {
            let fromConsle = localStorage.fromConsole;
            if(fromConsle) {
                fromConsle = JSON.parse(fromConsle);
                for(let i in fromConsle) {
                    this.config[i] = fromConsle[i]
                }
            }
        }
    }
})
