import request from '../utils/request';

/**
 * 获取系统配置
 * { IMEI } String 设备唯一识别码
 * @returns 
 */
export function getSystemConfig(data) {
    return request.post('/service-admin/api/android/getProjectParam ', data)
}

/**
 * 获取项目基础信息
 */
export function getSystemInfo(data) {
    return request.post('/service-core/api/android/getProjectConfig', data)
}

/**
 * 排名
 */
export function getRankingData(data) {
    return request.get('/service-bd/api/data/ranking/distance', data)
}

/**
 * 运动方式统计
 */
export function getMotionModelList(data) {
    return request.get('/service-bd/api/data/index/dim', data)
}

/**
 * 性别 年龄占比
 */
export function getSexOrAgeRegisterCount() {
    return request.post('/service-core/api/count/userCount')
}

/**
 * 获取注册总人数
 */
export function getRegisterCount() {
    return request.post('/service-core/api/count/registerCount')
}

/**
 * 获取累计人次
 */
 export function getNumberCount() {
    return request.get('/service-bd/api/data/index/number')
}

/**
 * 验证码
 */
export function getSendSMS(data) {
    return request.post('/service-core/api/android/sendSMS', data)
}

/**
 * 获取运动数据 统计
 */
export function getRunInfo(data) {
    return request.get('/service-bd/api/data/one/info', data)
}

/**
 * 获取运动数据 每一天的运动数据
 */
export function getRunRecord(data) {
    return request.get('/service-bd/api/data/one/record', data)
}

/**
 * 查看用户今日排名
 */
export function getUserRanking(data) {
    return request.get('/service-bd/api/data/one/ranking', data)
}

/**
 * 登陆
 */
export function login(data) {
    return request.post('/service-admin/oauth/token', data)
}

/**
 * 登录用户信息
 * @param {} data 
 * @returns 
 */
export function loginInfo(data) {
    return request.get('/service-admin/auth/info', data)
}