import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// 如果您正在使用CDN引入，请删除下面一行。
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
const app = createApp(App)

// 引入flexible 使用rem适应屏幕， 1rem = 80px;
import "./utils/flexible"
import "./permission"
// 引入全局 css
import "./assets/css/main.css"
import "swiper/dist/css/swiper.min.css";
import 'swiper/dist/js/swiper.min';
// 引入vant
import Vant from 'vant';
import "vant/lib/index.css"

// 引入elementplus
import ElementPlus from "element-plus"
import 'element-plus/dist/index.css'

//引入粒子特效
import VueParticles from "vue-particles"

// 引入 pinia
import { createPinia  } from 'pinia'

import "@vant/touch-emulator"

import { formattingImg } from "./utils/util"
//全局方法
app.config.globalProperties.$formattingImg = formattingImg

router.onError((error) => {
    const pattern = /Loading chunk (\d)+ failed/g;
    const isChunkLoadFailed = error.message.match(pattern);
    if (isChunkLoadFailed) {
        location.href = location.origin + location.pathname;
    }
  });


app.use(router)
app.use(Vant)
app.use(VueParticles)
app.use(ElementPlus)
app.use(createPinia())

app.mount('#app')
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
