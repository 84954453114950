import Cookies from "js-cookie";

const tokenKey = 'huiren_web_admin';
window.TokenKey = tokenKey;

export function getToken() {

    return window.localStorage.getItem(TokenKey);
}

export function setToken(token, seconds) {
    window.localStorage.setItem(TokenKey, token);
    // let expires = new Date(new Date() * 1 + seconds * 1000)
    // return Cookies.set(TokenKey, token, { expires: expires})
}

export function removeToken() {
    return window.localStorage.removeItem(TokenKey);
}