import router from "./router";
import { getToken, setToken } from "./utils/auth";
import { userInfoStore } from "./store/userInfo"

// 路由守卫
router.beforeEach(async (to, from, next) => {
    let userInfoStoreConfig = userInfoStore()

    if (to.query.token) {
        setToken(to.query.token)
    }
    let token = getToken();
    if (to.path === '/login')  next()
    if (!token) {
        next({ path: '/login' })
    } else {
        const hasRoles = userInfoStoreConfig.userInfo.roles && userInfoStoreConfig.userInfo.roles.length > 0
        if (hasRoles) {
            next()
        } else {
            await userInfoStoreConfig.getUserInfo() // http请求获取用户信息
            next({ ...to, replace: true })
        }
    }
})
