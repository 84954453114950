// 详情页的用户信息
import { defineStore } from "pinia";
import { loginInfo } from "../api/index";
import { getToken } from "../utils/auth";

export const userInfoStore = defineStore({
    id: 'myUserInfoStore',
    state: () => ({
        userInfo: {
            username: "",
            roles: [],
            name: "",
            mobile: 0,
            id: '',
            locations: "",
            dutyName: "",
            tokenValue: "",
            clientId: "",
            dutyCode: "",
        }
    }),

    actions: {
        setUserInfo(info) {
            for(let i in this.userInfo) {
                for(let j in info) {
                    if(i == j) this.userInfo[i] = info[j]
                }
            }
        },
        getUserInfo() {
            return new Promise((resolve, reject) => {
                loginInfo({ token: getToken() }).then(res => {
                    this.setUserInfo(res)
                    resolve()
                 }).catch(err => reject(err));
            })
            
        }
    }
})