import request from '../utils/request';

// 累计数据统计
export function getNumberCount(data) {
    return request.post('/service-bd/api/admin/index/number', data)
}

// 用户性别 年龄统计
export function getUserCount(data) {
    return request.post('/service-core/api/count/userCount', data)
}

// 设备列表
export function getDeviceCount() {
    return request.post('/service-core/api/sysDeviceMain/admin/device/statistics')
}

// 里程排行榜
export function rankingMotion(data) {
    return request.post('/service-bd/api/admin/ranking/distance', data)
}

// 卡路里排行榜
export function rankingCalorie(data) {
    return request.post('/service-bd/api/admin/ranking/calorie', data)
}

// 运动时长排行榜
export function rankingTime(data) {
    return request.post('/service-bd/api/admin/ranking/time', data)
}

// 新闻资讯
export function fetchNewList(data) {
    return request.post('/service-core/api/sysNotice/page', data)
}

// 设备互动时间排行
export function getDeviceRanking() {
    return request.post('/service-bigdata/api/deviceUse/admin/ranking')
}

// 后台管理 运动方式统计
export function getDimRUnMode(data) {
    return request.post('/service-bd/api/admin/index/dim', data)
}

// 获取当前账户的城市
export function getUserCity() {
    return request.post('/service-core/sysLocation/getCity')
}